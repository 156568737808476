import React from "react"
import DividerMaybe from "../DividerMaybe";

function PdfLink(agenda_url) {
    if (agenda_url) {
       // return (<span className="si_agenda pdf"><a href={agenda_url}>PDF</a></span>)
       return (<a href={agenda_url}>| PDF</a>)
    }
    return
}

function buildAgenda(schedule_item_id, schedule_item_category) {
    let link
    if (schedule_item_category == "committee") {
        link = `/schedule_items/${schedule_item_id}`;
    } else {
        link = `/floor/${schedule_item_id}`
    }
    return link
}

const ScheduleItemAgenda = (props) => {
    const { si_id, si_category, agenda_url } = props
    const agenda_path = <a href={buildAgenda(si_id, si_category)}>Agenda</a>;
    const pdf_link = PdfLink(agenda_url)

    return (
        <React.Fragment>
            <td className="col-2">
                {agenda_path} {pdf_link}
            </td>
        </React.Fragment>
    )
}

export default ScheduleItemAgenda
