import React from "react"
import InterimScheduleFull from './schedules/InterimScheduleFull'

class InterimSchedule extends React.Component {
    render() {
        return (
            <div className="schedule-wrapper">
                <InterimScheduleFull />
            </div>
        );
    }
}

export default InterimSchedule
