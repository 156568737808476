import React, { useState, useEffect } from "react"
import axios from 'axios'
import ScheduleItem from './ScheduleItem'
import ScheduleDayHeader from './ScheduleDayHeader'
import ScheduleItemHeader from './ScheduleItemHeader'
import moment from 'moment'

const AgendaUrl = (agenda_url) => {
    if (agenda_url == "") {
        return "No agenda"
    } else {
        return <a href={agenda_url}>View Agenda</a>
    }
}

const ScheduleItemsDayTabContent = (props) => {
    const [schedule_items, setScheduleItems] = useState([]);
    const [chamber, setChamber] = useState("joint")
    const date_str = moment(props.thedate).format("dddd, MMMM Do, YYYY")
    const tab_str = `ugh${ moment(props.thedate).format("YYYYMMDD") }`
    const tab_tab = `${tab_str}-tab`
    console.log(`chamber is ${chamber}`)
    useEffect(() => {
        axios.get("/api/v1/schedule_items.json", {
            params: {
                date: props.thedate,
                chamber: chamber,
                distinct: true
            }
        })
            .then(resp => setScheduleItems(resp.data.data))
            .catch(data => console.log('error', data))
    }, [schedule_items.length, chamber]);

    const grid = schedule_items.map((si, index) => {
        return (
            <ScheduleItem
                key={index}
                dt={si.attributes.time_str}
                activity={si.attributes.schedule_item_title}
                location={si.attributes.location}
                si_id={si.attributes.id}
                si_category={si.attributes.schedule_item_category}
                agenda_url={si.attributes.agenda_url}
                audio_id={si.attributes.audio_id}
            />
        )
    })

    return (
        <div className="tab-pane fade active show" id={tab_str} 
            role="tabpanel" aria-labelled={tab_tab} >
            <div className='table table-striped view-interim-schedule'>
                <ScheduleItemHeader />
                {grid}
            </div>
        </div>
    )
}

export default ScheduleItemsDayTabContent
