import React, { useState, useEffect } from "react"
import axios from 'axios'
import ScheduleItem from './ScheduleItem'
import ScheduleDayHeader from './ScheduleDayHeader'
import ScheduleItemHeader from './ScheduleItemHeader'
import moment from 'moment'

const ActivityURL = (activity_link, schedule_item_title, chamber, committee_type) => {
  if(activity_link == "") {
    return schedule_item_title
  } else {
    var chamber_title = IsCommitteeOfReference(committee_type) ? chamber : ""
    //var chamber_title = chamber
    return <a href={activity_link}> {chamber_title} {schedule_item_title}</a>
  }
}
const AgendaUrl = (agenda_url) => {
  if(agenda_url == "") {
    return "No agenda"
  } else {
    return <a href={agenda_url}>View Agenda</a>
  }
}

const IsCommitteeOfReference =(committee_type) =>{
 return committee_type.includes("Reference")
}

const ScheduleItemsDay = (props) => {
  const [schedule_items, setScheduleItems] = useState([]);
  const date_str = moment(props.thedate).format("dddd, MMMM Do, YYYY")
  useEffect(() => {
    axios.get("/api/v1/schedule_items.json", {
      params: {
        date: props.thedate,
        chamber: "joint",
        distinct: true
      }
    })
    .then( resp => setScheduleItems(resp.data.data))
    .catch( data => console.log('error', data))
  }, [schedule_items.length]);

  const grid = schedule_items.map( (si, index) => {
    console.log(si);
    return (
      <ScheduleItem
        key={index}
        dt={si.attributes.time_str}
        activity={ActivityURL(si.attributes.activity_link, si.attributes.schedule_item_title,
          si.attributes.chamber, si.attributes.committee_type)}
        location={si.attributes.location}
        si_id={si.attributes.id}
        si_category={si.attributes.schedule_item_category}
        agenda_url={si.attributes.agenda_url}
        audio_id={si.attributes.audio_id}
        event_id = {si.attributes.event_id}
      />
    )
  })

  return <React.Fragment>{grid}</React.Fragment>
}

export default ScheduleItemsDay
