// app/javascript/controllers/session_schedule_controller.js
import { Controller } from "@hotwired/stimulus";

const SCHEDULE_URL = "/schedule";

export default class extends Controller {
  static targets = ["views", "origins", "days"];

  connect() {
    this.toggleDayDropdown();
  }

  getView() {
    return this.hasViewsTarget ? this.viewsTarget.value : document.querySelector("#viewDropdown").value;
  }

  getChamber() {
    return this.hasOriginsTarget ? this.originsTarget.value : document.querySelector("#originDropdown").value;
  }

  sessionSchedules() {
    const view = this.getView();
    const chamber = this.getChamber();
    const date = this.hasDaysTarget ? this.daysTarget.value : document.querySelector("#dayDropdown").value;

    const url = SCHEDULE_URL;
    const params = { view, chamber, date };

    this.doTurboRequest(url, params);
    this.toggleDayDropdown();
  }

  toggleDayDropdown() {
    const view = this.getView();
    const dayDropdown = document.querySelector(".day-dropdown.date-dropdown-wrapper");

    dayDropdown.style.display = view === "week" ? "none" : "block";
  }

  doTurboRequest(url, params) {
    const queryString = Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

    const urlWithParams = `${url}?${queryString}`;

    fetch(urlWithParams, {
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    });
  }

  changeDay(direction) {
    const view = this.getView();
    const chamber = this.getChamber();
    const dateAttribute = direction === 'prev' ? 'data-session-schedule-prev-date' : 'data-session-schedule-next-date';
    const date = this.data.element.getAttribute(dateAttribute);

    const url = SCHEDULE_URL;
    const params = { view, chamber, date };

    this.doTurboRequest(url, params);
  }

  change() {
    this.sessionSchedules();
  }

  prevDay() {
    this.changeDay('prev');
  }

  nextDay() {
    this.changeDay('next');
  }
}
