import React from "react"
import moment from 'moment'
import SessionWeekNavigation from './SessionWeekNavigation'
import ScheduleChamberHeader from './ScheduleChamberHeader'

const SessionWeekHeader = (props) => {
  const thedate = moment(props.thedate);
  const today = moment();
  const date_fmt = "MMM D, YYYY"
  const date_start = thedate.startOf('week').format(date_fmt)
  const date_end = thedate.endOf('week').format(date_fmt)

  const handleOnClick = (duration) => {
    props.setActivitiesDurationValue(duration)
  }

  const activeChamber = (chamber) => {
    let chamberClasses = {
      joint: "px-3 py-1 rounded-border-sm",
      house: "px-3 py-1 rounded-border-sm",
      senate: "px-3 py-1 rounded-border-sm"
    }

    if (chamber === props.chamber) {
      chamberClasses[chamber] += " secondary-blue-backgroud text-white"
    }

    return chamberClasses[chamber];
  }

  const activeDuration = (duration) => {
    let durationClasses = {
      day: "px-3 py-1 rounded-border-sm",
      week: "px-3 py-1 rounded-border-sm",
    }

    if (duration === props.activitiesDuration) {
      durationClasses[duration] += " secondary-blue-backgroud text-white"
    }

    return durationClasses[duration];
  }

  return (
    <div className="pb-5">
      <div className="row p-0 d-flex align-items-end">
        <div className="col-md-4 p-0"></div>
        <div className="col-md-8 p-0">
          <div className="row p-0 d-flex align-items-end">
            <div className="col-md-5 p-md-0 py-4 d-flex justify-content-end order-1 order-md-0">
              <div className="schedule-day view-interim-schedule">
                <SessionWeekNavigation thedate={thedate}>
                  <div className="row p-0">
                    <div className="col-md-12 p-0 d-flex justify-content-center week-off-date">
                      {date_start}
                    </div>
                  </div>
                </SessionWeekNavigation>
              </div>
            </div>

            <div className="col-md-7 p-0 order-md-1 order-0">
              <div className="row p-0">
                <div className="col-md-5 col-12 p-0">
                  <div className="row p-md-0 py-2 d-flex justify-content-center">
                    <div className="col-md-12 col-6 px-2">
                      <div className="row p-1 schedule-day-main">
                        <div className="col-md-12 col-12 p-0">
                          <ul className="nav d-flex justify-content-around py-2 schedule-type">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                style={{ padding: 0 }}
                                href="#"
                              >
                                <span
                                  onClick={() => handleOnClick("day")}
                                  className={activeDuration('day')}>
                                    Day
                                </span>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                style={{ padding: 0 }}
                                href="#"
                              >
                                <span
                                  onClick={() => handleOnClick("week")}
                                  className={activeDuration('week')}>
                                    Week
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ScheduleChamberHeader
                  setChamberValue={props.setChamberValue}
                  activeChamber={activeChamber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionWeekHeader
