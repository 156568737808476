import React, { useState, useEffect } from "react"
import axios from 'axios'
import ScheduleItem from '../ScheduleItem'
import moment from 'moment'

const ActivityUrl = (activity_link, schedule_item_title, chamber, committee_type) => {
  if(activity_link == "") {
    return schedule_item_title
  } else {
    var chamber_title = IsCommitteeOfReference(committee_type) ? chamber : ""
    return <a href={activity_link}>{chamber_title} {schedule_item_title}</a>
  }
}

const IsCommitteeOfReference =(committee_type) =>{
    return committee_type.includes("Reference")
   }

function maybeGrid(grid) {
    console.log(`grid is ${grid}`)
    if (grid != "") {
        return grid
    }
    else {
        return
    }
}
function classNameString(thedate, myactivedate) {
    console.log(`in classNameString ${myactivedate} and ${thedate}`)
    if (thedate == myactivedate) {
        return "tab-pane active fade in show"
    }
    else {
        return "tab-pane fade in"
    }
}

const SessionScheduleDayTabContent = (props) => {
    const [schedule_items, setScheduleItems] = useState([]);
    var chamber = props.chamber
    const activedate = moment(props.activedate).format("YYYYMMDD")
    const thedate = moment(props.thedate).format("YYYYMMDD")
    const date_str = moment(props.thedate).format("dddd, MMMM Do, YYYY")
    const tab_str = `ugh${ moment(props.thedate).format("YYYYMMDD") }`
    const tab_tab = `${tab_str}-tab`
    console.log(`chamber is ${chamber}`)

    useEffect(() => {
        axios.get("/api/v1/schedule_items.json", {
            params: {
                date: props.thedate,
                chamber: chamber,
                distinct: true
            }
        })
            .then(resp => setScheduleItems(resp.data.data))
            .catch(data => console.log('error', data))
    }, [schedule_items.length, chamber]);

    const grid = schedule_items.map((si, index) => {
        return (
            <ScheduleItem
                key={index}
                dt={si.attributes.time_str}
                activity={ActivityUrl(si.attributes.activity_link, si.attributes.schedule_item_title,
                    si.attributes.chamber, si.attributes.committee_type)}
                location={si.attributes.location}
                si_id={si.attributes.id}
                si_category={si.attributes.schedule_item_category}
                agenda_url={si.attributes.agenda_url}
                audio_id={si.attributes.audio_id}
                event_id = {si.attributes.event_id}
            />
        )
    })
    return (
      <div
        className={classNameString(thedate, activedate)}
        id={tab_str}
        role="tabpanel"
        aria-labelledby={tab_tab}
      >
        <div className="row p-0 pb-md-5">{maybeGrid(grid)}</div>
      </div>
    )
}

export default SessionScheduleDayTabContent
