import React, { useContext } from "react";
import moment from "moment";
import ScheduleItemTableRow from "./ScheduleItemTableRow"
import { ScheduleContext } from './../ScheduleBlock';
import { activityUrl } from '../utilities/helpers'

const ScheduleDayInWeekContent = ({ schedule, counter }) => {
  const { days } = useContext(ScheduleContext);

  const daysContent = schedule.map((si, index) => {
    return (
      <ScheduleItemTableRow
        key={index}
        dt={si.attributes.time_str}
        activity={activityUrl(si.attributes.activity_link, si.attributes.schedule_item_title,
            si.attributes.chamber, si.attributes.committee_type)}
        location={si.attributes.location}
        si_id={si.attributes.id}
        si_category={si.attributes.schedule_item_category}
        agenda_url={si.attributes.agenda_url}
        audio_id={si.attributes.audio_id}
        event_id = {si.attributes.event_id}
      />
    )
  })

  return (
    <div className="tab-content pb-5">
      <div className="row pb-0 related-pub-heading freight-text-md-500">
        {moment(days[counter]).format('dddd - MMM D, YYYY').toUpperCase()}
      </div>
      <div className='py-md-0 py-3'>
        <div className='d-md-block d-none'>
          <div className="row session-law-header px-2 py-md-4">
            <div className="col-xl-8 col-md-6 col-12 px-0">
              <div className='row p-0'>
                <div className="col-xl-2 col-md-3 col-12 pl-lg-3 pr-0">
                  Date/Time
                </div>
                <div className="col-xl-10 col-md-9 col-12 pl-lg-5 pl-md-3 pr-0 px-0 py-0">
                  Activity
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 px-0">
              <div className='row p-0'>
                <div className="col-xl-5 col-5 px-2 py-0">
                  Location
                </div>
                <div className="col-xl-5 col-5 px-2 py-0">
                  Agenda
                </div>
                <div className="col-xl-2 col-2 px-2 py-0">
                  Audio
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {daysContent}
        </div>
      </div>
    </div>
  )
}

export default ScheduleDayInWeekContent
