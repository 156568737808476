import React, { useContext } from "react";
import moment from 'moment';
import { ScheduleContext } from './../ScheduleBlock';
import SessionWeekNavigation from "./SessionWeekNavigation";
import ScheduleChamberHeader from "./ScheduleChamberHeader";

const SessionWeekHeader = (props) => {
  const { days, setActivitiesDurationValue, activitiesDuration, chamber } = useContext(ScheduleContext);
  const { setIsDayContent } = props;
  const dateFmt = "MMM D, YYYY";
  const dateStart = moment(days[0]).startOf('week').format(dateFmt);

  const handleOnClick = (duration) => {
    setActivitiesDurationValue(duration)

    if (duration === 'day') {
      setIsDayContent(true)
    } else {
      setIsDayContent(false)
    }
  }

  const activeChamber = (ch) => {
    let chamberClasses = {
      joint: "px-3 py-1 rounded-border-sm",
      house: "px-3 py-1 rounded-border-sm",
      senate: "px-3 py-1 rounded-border-sm"
    }

    if (chamber === '' && ch === 'joint') return chamberClasses['joint'] += " secondary-blue-background text-white";

    if (ch === chamber) {
      chamberClasses[ch] += " secondary-blue-background text-white"
    }

    return chamberClasses[ch];
  }

  const activeDuration = (duration) => {
    let durationClasses = {
      day: "px-3 py-1 rounded-border-sm",
      week: "px-3 py-1 rounded-border-sm",
    }

    if (duration === activitiesDuration) {
      durationClasses[duration] += " secondary-blue-background text-white";
    }

    if (activitiesDuration === '') durationClasses['day'] += " secondary-blue-background text-white";

    return durationClasses[duration];
  }

  return (
    <div className="pb-5">
      <div className="row p-0 d-flex align-items-end">
        <div className="col-md-4 p-0"></div>
        <div className="col-md-8 p-0">
          <div className="row p-0 d-flex align-items-end">
            <div className="col-md-5 p-md-0 py-4 d-flex justify-content-end order-1 order-md-0">
              <div className="schedule-day view-interim-schedule">
                <SessionWeekNavigation>
                    <div className="row p-0">
                      <div className="col-md-12 p-0 d-flex justify-content-center week-off-date">
                        {dateStart}
                      </div>
                    </div>
                  </SessionWeekNavigation>
              </div>
            </div>

            <div className="col-md-7 p-0 order-md-1 order-0">
              <div className="row p-0">
                <div className="col-md-5 col-12 p-0">
                  <div className="row p-md-0 py-2 d-flex justify-content-center">
                    <div className="col-md-12 col-6 px-2">
                      <div className="row p-1 schedule-day-main">
                        <div className="col-md-12 col-12 p-0">
                          <ul className="nav d-flex justify-content-around py-2 schedule-type">
                            <li className="nav-item">
                              <div
                                className="nav-link pointer-link"
                                style={{ padding: 0 }}
                                href="#"
                              >
                                <span
                                  onClick={() => handleOnClick("day")}
                                  className={activeDuration('day')}>
                                    Day
                                </span>
                              </div>
                            </li>
                            <li className="nav-item">
                              <div
                                className="nav-link pointer-link"
                                style={{ padding: 0 }}
                                href="#"
                              >
                                <span
                                  onClick={() => handleOnClick("week")}
                                  className={activeDuration('week')}>
                                    Week
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ScheduleChamberHeader
                  activeChamber={activeChamber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionWeekHeader
