import React, { useContext } from "react";
import ScheduleItemTableRow from './ScheduleItemTableRow'
import moment from 'moment';
import { ScheduleContext } from './../ScheduleBlock';
import { mayBeGrid, activityUrl } from '../utilities/helpers'

const isActivetab = (theDate, myactivedate) => {
  if (theDate == myactivedate) return "tab-pane active fade in show";

  return "tab-pane fade in";
}

const SessionScheduleDayTabContent = (props) => {
  const { scheduleItems } = useContext(ScheduleContext);
  const activeDate = moment(props.activeDate).format("YYYYMMDD")
  const theDate = moment(props.theDate).format("YYYYMMDD")
  const tabStr = `ugh${ moment(props.theDate).format("YYYYMMDD") }`
  const tabTab = `${tabStr}-tab`
  const currentScheduleItems = scheduleItems[props.counter];
  let grid;

  if (!!currentScheduleItems) {
    grid = currentScheduleItems.map((si, index) => {
      return (
        <ScheduleItemTableRow
          key={index}
          dt={si.attributes.time_str}
          activity={activityUrl(si.attributes.activity_link, si.attributes.schedule_item_title,
              si.attributes.chamber, si.attributes.committee_type)}
          location={si.attributes.location}
          si_id={si.attributes.id}
          si_category={si.attributes.schedule_item_category}
          agenda_url={si.attributes.agenda_url}
          audio_id={si.attributes.audio_id}
          event_id = {si.attributes.event_id}
        />
      )
    })
  }

  return (
    <div className={isActivetab(theDate, activeDate)} id={tabStr} role="tabpanel" aria-labelledby={tabTab}>
        <div className="row p-0 pb-md-5">
            <div className='col-md-12 px-0'>
                <div className='py-md-0 py-3'>
                    <div className='d-md-block d-none'>
                        <div className="row session-law-header px-2 py-md-4">
                            <div className="col-xl-8 col-md-6 col-12 px-0">
                                <div className='row p-0'>
                                    <div className="col-xl-2 col-md-3 col-12 pl-lg-3 pr-0">
                                        Date/Time
                                    </div>
                                    <div className="col-xl-10 col-md-9 col-12 pl-lg-5 pl-md-3 pr-0 px-0 py-0">
                                        Activity
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-12 px-0">
                                <div className='row p-0'>
                                    <div className="col-xl-5 col-5 px-2 py-0">
                                        Location
                                    </div>
                                    <div className="col-xl-5 col-5 px-2 py-0">
                                        Agenda
                                    </div>
                                    <div className="col-xl-2 col-2 px-2 py-0">
                                        Audio
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {mayBeGrid(grid)}
            </div>
        </div>
    </div>
  )
}

export default SessionScheduleDayTabContent
