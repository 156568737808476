import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const defaultConfig = {
      searchField: [],
      controlInput: null,

      onDropdownOpen: function() {
        let dropdown = this.dropdown;
        dropdown.classList.remove('open-upward');

        let dropdownContent = dropdown.querySelector('.ts-dropdown-content');
        let distanceToBottom = window.innerHeight - dropdownContent.getBoundingClientRect().bottom + dropdownContent.offsetHeight;

        if (distanceToBottom < dropdownContent.offsetHeight) {
          dropdown.classList.add('open-upward');
        }
      }
    };

    if (this.element.id === 'committee-hearing-pick-a-date') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        },
        item: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        }
      };
    }

    // Initialize Tom Select when the controller is connected
    new TomSelect(this.element, defaultConfig);
  }
}
