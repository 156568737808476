import React from "react"
import PropTypes from "prop-types"
import ScheduleItems from './schedules/ScheduleItems'

class SessionSchedule extends React.Component {
  render () {
    return (
      <React.Fragment>
        <h3>Greetings, {this.props.greeting}</h3>
        <ScheduleItems />
      </React.Fragment>
    );
  }
}

SessionSchedule.propTypes = {
  greeting: PropTypes.string
};
export default SessionSchedule
