import React from "react"

const ScheduleDayHeader = (props) => {
  return (
    <div className="schedule-day view-interim-schedule">
      <h3>{props.date_string}</h3>
    </div>
  )
}

export default ScheduleDayHeader
