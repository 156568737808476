import React from "react"
import headphones from "images/icons/headphones"

const ScheduleItem = (props) => {
  const { dt, activity, location, audio_id, si_id, si_category, agenda_url, event_id } = props
  const audio_url = "/committee_audio/" + audio_id
  return (
    <div className="col-xl-4 col-md-6 p-0 interim-schedule-cards">
      <div className="card bills-card-height p-0 background-white">
        <div className="card-body p-0">
          <div className='pb-1'>
            <div className="row pb-1 d-flex justify-content-between">
              <div className="text-white px-2 session-law-bill-tag py-1">
                Friday, June 24
              </div>
              <div className="session-schedule-time">8:30 am</div>
            </div>
          </div>
          <div className="card-text session-card-body-height gibson-20 pt-md-2 pt-1">
            Early Childhood and School Readiness Legislative Commission
          </div>

          <div className=''>
            <hr style={{border: '0.5px solid #EAEAE9', margin: 0}}/>

            <div className='pt-md-3 pb-md-0 py-3'>
              <div className="row p-0">
                <div className="col-md-10 col-8 p-0 d-flex align-items-center">
                  <div className="row p-0">
                    <div className="schedule-day-location pr-3">
                      Old State Library
                    </div>
                    <div className="p-0 primary-blue-text follow-link d-flex align-items-center">
                      <a href={agenda_url}>Agenda</a>
                    </div>
                  </div>
                </div>

                <div className="col-md-2 col-4 p-0 d-flex justify-content-end">
                  <div>
                    <img src={headphones} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleItem
