import React, { useContext } from "react"
import { ScheduleContext } from './../ScheduleBlock';

const ScheduleChamberHeader = (props) => {
  const { setChamberValue } = useContext(ScheduleContext);

  const handleOnClick = (ch) => {
    setChamberValue(ch);
  }

  return (
    <div className="col-md-7 col-12 pr-0">
      <div className="row p-md-0 py-2 d-flex justify-content-center">
        <div className="col-md-12 col-10 px-md-0 px-1 py-1 schedule-type-main">
          <ul className="nav d-flex justify-content-around py-2 px-1 schedule-type">
            <li className="nav-item">
              <div className="nav-link pointer-link" style={{ padding: 0 }}>
                <span
                  onClick={() => handleOnClick("joint")}
                  id="joint"
                  className={`${props.activeChamber("joint")} px-4`}
                >
                  All
                </span>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link pointer-link" style={{ padding: 0 }}>
                <span
                  onClick={() => handleOnClick("house")}
                  id="house"
                  className={props.activeChamber("house")}
                >
                  House
                </span>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link pointer-link" style={{ padding: 0 }}>
                <span
                  onClick={() => handleOnClick("senate")}
                  id="senate"
                  className={props.activeChamber("senate")}
                >
                  Senate
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ScheduleChamberHeader
