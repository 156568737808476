import React from "react"
import headphones from "images/icons/headphones"

const ScheduleItemTableRow = (props) => {
  const { dt, activity, location, audio_id, si_id, si_category, agenda_url, event_id } = props
  const audio_url = "/committee_audio/" + audio_id
  return (
    <div className="row session-law-data px-md-2 px-0 mobile-view-padding py-lg-0 py-md-2 py-3 background-white active-row-border">
      <div className="col-xl-8 col-md-6 col-12 px-0 pt-lg-1">
        <div className='row p-0 pt-2'>
          <div className="p-0 pt-3 session-law-header d-md-none d-block pr-md-0 pr-2 px-0">
            <div className='row p-0'>
              <div className="col-12 px-0 pt-1">
                Date/Time
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-3 col-6 pl-lg-3 pr-0 pt-md-0 pt-3 pl-md-0 pl-1">
            <span className='mb-1 text-white session-law-bill-tag py-1 px-2'>
              8:00 AM
            </span>
          </div>
          <div className="col-12 px-0 d-md-none d-block p-0 pb-lg-3 pb-1 pt-lg-4 pt-3 session-law-header">
            <div className='row p-0'>
              <div className="col-12 px-0">
                Activity
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-md-9 col-12 pl-lg-5 pl-md-1 pr-0 px-0 py-0 bill-topic bill-topic-to-link">
            House Energy & Environment; Senate Transportation & Energy
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-md-6 col-12 px-0 pt-md-1 pb-md-0 pb-1">
        <div className='row pb-md-0 pb-3 pt-md-2'>
          <div className="col-12 px-0">
            <div className='d-md-none d-block'>
              <div className='row p-0 pt-4 session-law-header'>
                <div className="col-12 px-0">
                  <div className='row p-0'>
                    <div className="col-5 px-0">
                      Location
                    </div>
                    <div className="col-5 px-0">
                      Agenda
                    </div>
                    <div className="col-2 px-0">
                      Audio
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row p-0'>
              <div className="col-xl-5 col-5 px-md-2 px-0 py-0">
                Old Supreme Court
              </div>
              <div className="col-xl-5 col-5 px-lg-2 pl-md-2 px-0 py-0">
                <a href={agenda_url}>Agenda</a>

              </div>
              <div className="col-xl-2 col-2 px-lg-2 pl-md-3 px-0 py-0">
                <img src={headphones} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleItemTableRow
