import React, { useContext } from "react";
import ScheduleWeekTabContent from "./ScheduleWeekTabContent";
import SessionScheduleDayTabContent from "./SessionScheduleDayTabContent";
import { ScheduleContext } from './../ScheduleBlock';
import { getActiveScheduleDays } from '../utilities/helpers'

const SessionWeekTabsContent = (props) => {
  const { days, isDayContent, noScheduleDays } = useContext(ScheduleContext);

  let activeDate = getActiveScheduleDays(days,noScheduleDays);

  const tabsContent = days.map((myDay, id) => {
    return (
      <SessionScheduleDayTabContent key={id} theDate={myDay} activeDate={activeDate} counter={id} />
    )
  })

  if (isDayContent) {
    return (
      <div className="tab-content">
        {tabsContent}
      </div>
    )
  } else {
    return <ScheduleWeekTabContent />
  }
}

export default SessionWeekTabsContent
