import React from "react"
import moment from 'moment'
import carouselLeftArrow from "images/icons/carousel-arrow-left-sm"
import carouselRightArrow from "images/icons/carousel-arrow-right-sm"

function maybe_lastweek(endlastweek) {
    if(endlastweek > moment()) {
        const beginlastweek = moment(endlastweek).startOf("isoWeek").format("YYYYMMDD")
        const begin_url = `/session-schedule?date=${beginlastweek}`
        return (
          <a href={begin_url} className="prev-week pr-4">
            <img src={carouselLeftArrow} />
          </a>
        )
    } else if (endlastweek <= moment()) {
      const beginlastweek = moment(endlastweek)
        .startOf("isoWeek")
        .format("YYYYMMDD")
      const begin_url = `/session-schedule?date=${beginlastweek}`
      return (
        <a className="previous-week pr-4 disabled">
          <img src={carouselLeftArrow} />
        </a>
      )
    }
    return
}

const SessionWeekNavigation = (props) => {
    console.log(`the date ${props.thedate}`)
    const thedate = props.thedate
    const nextweek = moment(thedate).add(1, 'week').startOf('isoWeek').format("YYYYMMDD")
    const lastweek = moment(thedate).add(-1, 'week').startOf('isoWeek')
    const endlastweek = moment(thedate).add(-1, 'week').endOf('isoWeek')
    const next_link = `/session-schedule?date=${nextweek}`
    return (
      <div className="week-nav">
        <div className="row p-0">
          <div className="col-md-12 p-0 d-flex justify-content-center week-off pb-3">
            WEEK OF
          </div>
          <div className="col-md-12 d-flex justify-content-center">
            {maybe_lastweek(endlastweek)}
            {props.children}
            <a
              href={next_link}
              className="use-ajax next-week ajax-processed pl-4"
            >
              <img src={carouselRightArrow} />
            </a>
          </div>
        </div>
      </div>
    )
}
export default SessionWeekNavigation
