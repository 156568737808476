import React from "react"


const ScheduleChamberHeader = (props) => {
  const handleOnClick = (chamber) => {
    props.setChamberValue(chamber)
  }

  return (
    <div className="col-md-7 col-12 pr-0">
      <div className="row p-md-0 py-2 d-flex justify-content-center">
        <div className="col-md-12 col-10 px-md-0 px-1 py-1 schedule-type-main">
          <ul className="nav d-flex justify-content-around py-2 px-1 schedule-type">
            <li className="nav-item">
              <a className="nav-link" style={{ padding: 0 }} href="">
                <span
                  onClick={() => handleOnClick("joint")}
                  id="joint"
                  className={props.activeChamber("joint")}
                >
                  All
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={{ padding: 0 }} href="">
                <span
                  onClick={() => handleOnClick("house")}
                  id="house"
                  className={props.activeChamber("house")}
                >
                  House
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" style={{ padding: 0 }} href="">
                <span
                  onClick={() => handleOnClick("senate")}
                  id="senate"
                  className={props.activeChamber("senate")}
                >
                  Senate
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ScheduleChamberHeader
