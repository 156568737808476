import React, { useState, useEffect, createContext } from "react";
import moment from "moment";
import axios from 'axios';
import MediaQuery from "react-responsive";
import SessionWeekHeader from "./schedule/SessionWeekHeader";
import SessionWeekTabs from "./schedule/SessionWeekTabs";
import SessionWeekTabsContent from "./schedule/SessionWeekTabsContent";
import ScheduleWeekTabContent from "./schedule/ScheduleWeekTabContent";
import { buildWeek } from './utilities/helpers'

export const ScheduleContext = createContext();

const ScheduleBlock = (props) => {
  const [displayDate, setDisplayDate] = useState(moment(props.theDate).startOf("week").add(1,'days'));
  const [chamber, setChamber] = useState('');
  const [days, setDays] = useState(buildWeek(displayDate));
  const [activitiesDuration, setActivitiesDuration] = useState('');
  const [currentDateCounter, setCurrentDateCounter] = useState(0);
  const [noScheduleDays, setNoScheduleDays] = useState([]);
  const [scheduleItems, setScheduleItems] = useState([]);
  const [isDayContent, setIsDayContent] = useState(true);

  const valuesForScheduleContext = () => {
    return {
      days,
      setDays,
      scheduleItems,
      displayDate,
      updateDisplayDateToFirstDayOfWeek,
      setCurrentDateCounter,
      noScheduleDays,
      chamber,
      setChamberValue,
      activitiesDuration,
      setActivitiesDurationValue,
      isDayContent
    }
  }

  let params = {
    date: days[currentDateCounter],
    chamber: chamber,
    distinct: true,
  }

  const setChamberValue = (chamber) => {
    setChamber(chamber);
  }

  const setActivitiesDurationValue = (duration) => {
    setActivitiesDuration(duration);
  }

  const updateDisplayDateToFirstDayOfWeek = (date) => {
    setDisplayDate(moment(date));
  }

  useEffect(() => {
    axios
      .get('/api/v1/schedule_items', { params })
      .then((resp) => {
        if (currentDateCounter <= 6) {
          if (currentDateCounter < 6) setCurrentDateCounter(currentDateCounter + 1);

          setNoScheduleDays((oldArray) => [...oldArray, resp.data.data.length === 0]);
          setScheduleItems(prevState => [...prevState, resp.data.data]);
        }
      })
      .catch((data) => console.log("error", data))
  }, [currentDateCounter, chamber])

  if (props.inSession) {
    return (
      <ScheduleContext.Provider value={valuesForScheduleContext()}>
        <div className="session-schedule-wrapper">
          <div className="bg-white">
            <div className="container-fluid">
              <SessionWeekHeader
                setIsDayContent={setIsDayContent}
              />
            </div>
          </div>

          <MediaQuery maxWidth={767}>
            <div className="session-calendar">
              <SessionWeekTabs />
            </div>
          </MediaQuery>

          <MediaQuery minWidth={768}>
            <div className="session-calendar container-fluid">
              <SessionWeekTabs />
              <SessionWeekTabsContent />
            </div>
          </MediaQuery>
        </div>
      </ScheduleContext.Provider>
    )
  } else {
    return (
      <ScheduleContext.Provider value={valuesForScheduleContext()}>
        <div className="interim-schedule-wrapper pt-md-5 container-fluid">
          <ScheduleWeekTabContent />
        </div>
      </ScheduleContext.Provider>
    )
  }
}

export default ScheduleBlock
