import React from "react"
import ScheduleItemsDay from './schedules/ScheduleItemsDay'
import InterimScheduleFull from './schedules/InterimScheduleFull'
class InterimScheduleBlock extends React.Component {
  render () {
    return (
      <div className="schedule-wrapper">
        <h2>Interim Schedule</h2>
        <InterimScheduleFull count='4' />
        <div className="link-wrapper">
          <a href="/interim-schedule" className="btn btn-secondary">View Full Schedule</a>
        </div>
      </div>
    );
  }
}

export default InterimScheduleBlock
