import React, { useContext } from "react"
import ScheduleDayTabHeader from "./ScheduleDayTabHeader"
import { ScheduleContext } from './../ScheduleBlock';

const SessionWeekTabs = (props) => {
  const { days } = useContext(ScheduleContext);

  const tabs = days.map((myDay, index) => {
    return (
      <ScheduleDayTabHeader
        key={index}
        theDate={myDay}
        counter={index}
      />
    )
  })

  return (
    <div className="tabs schedule-wrapper pt-5">
      <ul id="myTab" role="tablist" className="nav schedule-session-calendar nav-fill">
        {tabs}
      </ul>
    </div>
  )
}
export default SessionWeekTabs
