import React, { useState, useEffect } from "react"
import axios from 'axios'
import ScheduleItemsDay from './ScheduleItemsDay'
import ScheduleItemsDayTabContent from "./ScheduleItemsDayTabContent";

const SessionScheduleFull = (props) => {
    const [schedule_days, setScheduleDays] = useState([]);
    const thedate = props.thedate

    useEffect(() => {
        axios.get("/api/v1/schedule_week.json", {
            params: {
                chamber: "joint",
                date: thedate,
            }
        })
            //.then(resp => console.log(resp.data))
            .then(resp => setScheduleDays(resp.data))
            .catch(data => console.log('error', data))
    }, [schedule_days.length]);

    const grid = schedule_days.map((day, index) => {
        return (
            <ScheduleItemsDayTabContent key={index} thedate={day} />
        )
    })

    return (
        <React.Fragment>
            {grid}
        </React.Fragment>
    )
}

export default SessionScheduleFull
