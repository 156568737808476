import React, { useContext } from "react";
import ScheduleDayInWeekContent from "./ScheduleDayInWeekContent"
import { ScheduleContext } from './../ScheduleBlock';

const ScheduleWeekTabContent = (props) => {
  const { scheduleItems } = useContext(ScheduleContext);

  let grid;

  if (!!scheduleItems) {
    grid = scheduleItems.map((el, i) => {
      return (
        <ScheduleDayInWeekContent
          key={i}
          counter={i}
          schedule={el}
        />
      )
    })
  }

  return (
    <div className="tab-content">
      <div>
        { grid }
      </div>
    </div>
  )
}

export default ScheduleWeekTabContent
