import React, { useState, useEffect } from "react"
import axios from 'axios'
import moment from "moment"
import SessionWeekHeader from './SessionWeekHeader'
import SessionWeekTabs from "./SessionWeekTabs"
import SessionWeekTabsContent from "./SessionWeekTabsContent"
import MediaQuery from "react-responsive"

function build_week(date) {
  const startOfWeek = moment(date).startOf("week")
  const endOfWeek = moment(date).endOf("week")
  var days = []
  var day = startOfWeek
  while (day <= endOfWeek) {
    days.push(day.toDate())
    day = day.clone().add(1, "d")
  }
  return days
}

const SessionScheduleBlock = (props) => {
    const indate = props.thedate
    const [chamber, setChamber] = useState('')
    const thedate = moment(indate).format("YYYYMMDD");
    const days = build_week(thedate)
    const [currentDateCounter, setCurrentDateCounter] = useState(0)
    const [currentDate, setCurrentDate] = useState(days[currentDateCounter])
    const [noScheduleDays, setNoScheduleDays] = useState([])
    const [activitiesDuration, setActivitiesDuration] = useState('')

    const setChamberValue = (chamber) => {
      setChamber(chamber);
    }

    const setActivitiesDurationValue = (duration) => {
      setActivitiesDuration(duration);
    }

    useEffect(() => {
      const chamber = localStorage.getItem('chamber');
      if (chamber) {
       setChamber(chamber);
      }

      const activitiesDuration = localStorage.getItem('activitiesDuration');
      if (activitiesDuration) {
        setActivitiesDuration(activitiesDuration)
      }
    }, [])

    useEffect(() => {
      localStorage.setItem('chamber', chamber);
    }, [chamber])

    useEffect(() => {
      localStorage.setItem('activitiesDuration', activitiesDuration);
    }, [activitiesDuration])

    console.log(`date is ${thedate}`)

    useEffect(() => {
        console.log('hi');
        axios
          .get("/api/v1/schedule_items.json", {
            params: {
              date: currentDate,
              chamber: chamber,
              distinct: true,
            },
          })
          .then((resp) => {
            if (currentDateCounter <= 6) {
                // console.log("dayssss", days)
                // console.log("currentDateCounter", currentDateCounter)
                setCurrentDate(days[currentDateCounter + 1])
                setCurrentDateCounter(currentDateCounter + 1)
                if (resp.data.data.length == 0) {
                    setNoScheduleDays((oldArray) => [...oldArray, true])
                } else {
                    setNoScheduleDays((oldArray) => [...oldArray, false])
                }
            }
        })
          .catch((data) => console.log("error", data))
    }, [currentDateCounter])

    console.log("no Schedule days", noScheduleDays)
    return (
      <div className="session-schedule-wrapper">
        <SessionWeekHeader
          chamber={chamber}
          thedate={thedate}
          setChamberValue={setChamberValue}
          activitiesDuration={activitiesDuration}
          setActivitiesDurationValue={setActivitiesDurationValue}
        />

        <MediaQuery maxWidth={767}>
          <div className="session-calendar">
            <SessionWeekTabs
              thedate={thedate}
              noScheduleDays={noScheduleDays}
              chamber={chamber}
              days={days}
            />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <div className="session-calendar">
            <SessionWeekTabs
              thedate={thedate}
              noScheduleDays={noScheduleDays}
              days={days}
            />
            <SessionWeekTabsContent
              thedate={thedate}
              chamber={chamber}
              activedate={days[noScheduleDays.findIndex((el) => el === false)]}
              days={days}
            />
          </div>
        </MediaQuery>
      </div>
    )
}

export default SessionScheduleBlock
