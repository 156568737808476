import React, { useContext } from "react"
import moment from 'moment'
import MediaQuery from "react-responsive"
import ScheduleItemTableRow from "./ScheduleItemTableRow"
import { ScheduleContext } from './../ScheduleBlock';
import { mayBeGrid, activityUrl, getActiveScheduleDays } from '../utilities/helpers'

const maybeActive = (active, myDate, isDayContent) => {
  if (active === myDate && isDayContent) {
    return "nav-link active";
  } else if(active !== myDate && isDayContent) {
      return "nav-link";
  } else if(!isDayContent) {
    return 'nav-link no-pointer-events';
  }
}

const ScheduleDayTabHeader = ({ theDate, counter }) => {
  const { days, noScheduleDays, scheduleItems, isDayContent } = useContext(ScheduleContext);
  const noScheduleDay = noScheduleDays[counter]

  const myDate = moment(theDate).format("YYYYMMDD")
  const dateStr = moment(theDate).format("MMM DD")
  const dayOfWeek = moment(theDate).format("dddd")
  const tabStr = `ugh${moment(theDate).format("YYYYMMDD")}`
  const tabTab = `ugh${tabStr}-tab`
  const tabLink = `#${tabStr}`
  const currentScheduleItems = scheduleItems[counter];

  let activeDate = moment(getActiveScheduleDays(days,noScheduleDays)).format("YYYYMMDD");

  let grid;

  if (!!currentScheduleItems) {
    grid = currentScheduleItems.map((si, index) => {
      return (
        <ScheduleItemTableRow
          key={index}
          dt={si.attributes.time_str}
          activity={activityUrl(si.attributes.activity_link, si.attributes.schedule_item_title,
              si.attributes.chamber, si.attributes.committee_type)}
          location={si.attributes.location}
          si_id={si.attributes.id}
          si_category={si.attributes.schedule_item_category}
          agenda_url={si.attributes.agenda_url}
          audio_id={si.attributes.audio_id}
          event_id = {si.attributes.event_id}
        />
      )
    })
  }

  return (
    <>
      <MediaQuery minWidth={768}>
        <>
          <li className="nav-item">
            <a
              className={maybeActive(activeDate, myDate, isDayContent)}
              data-toggle={noScheduleDay ? "" : "tab"}
              href={tabLink}
              id={tabTab}
              role="tab"
              aria-controls={tabStr}
              aria-selected="true"
            >
              <div>
                <div className="row p-0 pb-2 d-flex justify-content-center session-schedule-day">
                  <span
                    className={
                      noScheduleDay
                        ? "session-schedule-week-day p-1"
                        : "background-white p-1"
                    }
                  >
                    {dayOfWeek.toUpperCase()}
                  </span>
                </div>

                <div
                  className={
                    noScheduleDay
                      ? "row p-0 pb-2 d-flex justify-content-center session-schedule-date disabled-session-schedule"
                      : `row p-0 pb-2 d-flex justify-content-center ${isDayContent ? 'schedule-date' : ''} session-schedule-date`
                  }
                >
                  <span>{dateStr.toUpperCase()}</span>
                </div>
              </div>
            </a>
          </li>
        </>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <div className="row p-0 py-2">
          <div className="col-12">
            <a
              className={maybeActive(activeDate, myDate, isDayContent)}
              data-toggle={noScheduleDay ? "" : "tab"}
              href={tabLink}
              id={tabTab}
              role="tab"
              aria-controls={tabStr}
              aria-selected="true"
            >
              <div>
                <div className="row p-0 pb-2 d-flex justify-content-center session-schedule-day">
                  <span
                    className={
                      noScheduleDay
                        ? "session-schedule-week-day p-1"
                        : "background-white p-1"
                    }
                  >
                    {dayOfWeek.toUpperCase()}
                  </span>
                </div>

                <div
                  className={
                    noScheduleDay
                      ? "row p-0 pb-2 d-flex justify-content-center session-schedule-date disabled-session-schedule"
                      : `row p-0 pb-2 d-flex justify-content-center ${isDayContent ? 'schedule-date' : ''} session-schedule-date`
                  }
                >
                  <span>{dateStr.toUpperCase()}</span>
                </div>
              </div>
            </a>
          </div>

          <div className="col-12 p-0 pb-md-5">
            {mayBeGrid(grid)}
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

export default ScheduleDayTabHeader
