import React, { useState, useEffect } from "react"
import axios from 'axios'
import ScheduleItem from './ScheduleItem'
import ScheduleDayHeader from './ScheduleDayHeader'
import ScheduleItemHeader from './ScheduleItemHeader'

const ScheduleItems = () => {
  const [schedule_items, setScheduleItems] = useState([]);

  useEffect(() => {
    axios.get('/api/v1/schedule_items.json')
      //.then( resp => console.log(resp.data.data) )
      .then(resp => setScheduleItems(resp.data.data))
      .catch(data => console.log('error', data))
  }, [schedule_items.length]);


  const grid = schedule_items.map((si, index) => {
    return (
      <ScheduleItem
        key={index}
        dt={si.attributes.time_str}
        activity={si.attributes.schedule_item_title}
        location={si.attributes.location}
        calendar='No calendar'
        audio_id={si.attributes.sliq_id}
      />
    )
  })

  return (
    <div className="schedule_day">
      <ScheduleDayHeader date_string="Monday, September 21st, 2020" />
      <table className='table table-striped'>
        <thead>
          <ScheduleItemHeader />
        </thead>
        <tbody>
          {grid}
          <ScheduleItem dt="2:30pm" activity="Joint Technology Committee" location="HCR 091" calendar="No calendar" audio="" />
        </tbody>
      </table>
    </div>
  )
}

export default ScheduleItems
