import React from "react"

const ScheduleItemHeader = () => {
  return (
    <thead>
      <tr className="schedule-item-header row">
        <th className="col-2 datetime">Date/Time</th>
        <th className="col-5 activity">Activity</th>
        <th className="col-2 location">Location</th>
        <th className="col-2 agenda">Agenda</th>
        <th className="col-1 audio">Audio</th>
      </tr>
    </thead>
  )
}

export default ScheduleItemHeader
