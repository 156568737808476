import React, { useState, useEffect } from "react"
import axios from 'axios'
import ScheduleItemsDay from './ScheduleItemsDay'
import Pagination from '../Pagination'
import arrowRightWhite from "../../../assets/images/icons/arrow-right-md-white.svg";

const InterimScheduleFull = ({ isPaginationNeeded = true, recordsPerPage = 18 }) => {
    const [schedule_days, setScheduleDays] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(recordsPerPage)
    const [totalCount, setTotalCount] = useState(0)
    const [currentPageDataCount, setCurrentPageDataCount] = useState(perPage)

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    useEffect(() => {
      axios
        .get("/api/v1/schedule_days.json", {
          params: {
            per_page: perPage,
            page: currentPage,
            distinct: true,
          },
        })
        .then((resp) => {
          setScheduleDays(resp.data.si)
          setTotalCount(resp.data.meta_data.total_count)
          setCurrentPageDataCount(resp.data.si.length)
        })
        .catch((data) => {
          setScheduleDays([])
        })
    }, [currentPage])

    const grid = schedule_days.map((day, index) => {
        return (
            <ScheduleItemsDay key={index} thedate={day} />
        )
    })

    return (
      <div className="mobile-view-padding tablet-view-design">
        { isPaginationNeeded ?
          <div className="py-3">
            <Pagination
              paginate={paginate}
              perPage={perPage}
              totalCount={totalCount}
              currentPage={currentPage}
              currentPageDataCount={currentPageDataCount}
            />
          </div>
          : ''
        }
        <div className="row p-0">
          {grid}
          { isPaginationNeeded ? '' :
            <div className="col-xl-4 col-md-6 p-0 full-interim-schedule-card-wrapper d-md-block d-none">
              <div className="row p-0 card full-interim-schedule-card background-white d-flex align-items-center justify-content-center">
                <div className="p-0 background-white">
                  <div className="">
                    <a href="/interim-schedule" className='primary-btn pointer-link full-width-btn-mobile pl-4 pr-2 btn btn-md poster-btn-text font-400 right-poster-btn text-center d-flex align-items-center justify-content-md-start justify-content-center'>
                      <div className="d-flex flex-row">
                        <div className='text-white d-flex align-items-center pl-3'> ...Full Schedule </div>
                        <div className='pl-2 pr-5'> <img src={arrowRightWhite}/> </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        { isPaginationNeeded ?
          <div className="py-3">
            <Pagination
              paginate={paginate}
              perPage={perPage}
              totalCount={totalCount}
              currentPage={currentPage}
              currentPageDataCount={currentPageDataCount}
            />
          </div>
          : ''
        }
      </div>
    )
}

export default InterimScheduleFull
