import React from "react"
import moment from 'moment'
import ScheduleDayTabHeader from "./ScheduleDayTabHeader"

const SessionWeekTabs = (props) => {
    const { noScheduleDays } = props
    var chamber = props.chamber
    const thedate = props.thedate
    const nextweek = moment(thedate).add(1, 'week').startOf('isoWeek').format("YYYYMMDD")
    const lastweek = moment(thedate).add(-1, 'week').startOf('isoWeek')
    const endlastweek = moment(thedate).add(-1, 'week').endOf('isoWeek')
    const next_link = `/session-schedule?date=${nextweek}`
    const days = props.days
    const tabs = days.map((myday, index) => {
        return (
          <ScheduleDayTabHeader
            key={index}
            thedate={myday}
            activedate={days[noScheduleDays.findIndex((el) => el === false)]}
            chamber={chamber}
            noScheduleDay={noScheduleDays[index]}
          />
        )
    })

    return (
      <div className="tabs schedule-wrapper py-5">
        <ul id="myTab" role="tablist" className="nav schedule-session-calendar nav-fill">
          {tabs}
        </ul>
      </div>
    )
}
export default SessionWeekTabs
