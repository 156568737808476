import React, { useContext } from "react";
import moment from 'moment';
import { ScheduleContext } from './../ScheduleBlock';
import carouselLeftArrow from "images/icons/carousel-arrow-left-sm";
import carouselRightArrow from "images/icons/carousel-arrow-right-sm";
import { buildWeek } from '../utilities/helpers'

const SessionWeekNavigation = (props) => {
  const { displayDate, setCurrentDateCounter, updateDisplayDateToFirstDayOfWeek, setDays } = useContext(ScheduleContext);
  const nextWeek = moment(displayDate).isoWeekday(8);
  const endLastWeek = moment(displayDate).isoWeekday(-1);

  const otherWeekData = (otherWeek) => {
    updateDisplayDateToFirstDayOfWeek(otherWeek);
    setDays(buildWeek(otherWeek));
    setCurrentDateCounter(0);
  }

  const mayBeLastWeek = (endLastWeek) => {
    if(endLastWeek > moment()) {
      return (
        <div
          onClick={() => otherWeekData(endLastWeek)}
          className="prev-week pr-4 pointer-link">
          <img src={carouselLeftArrow} />
        </div>
      )
    } else if (endLastWeek <= moment()) {
      return (
        <a className="previous-week pr-4 disabled">
          <img src={carouselLeftArrow} />
        </a>
      )
    }

    return
  }

  return (
    <div className="week-nav">
      <div className="row p-0">
        <div className="col-md-12 p-0 d-flex justify-content-center week-off pb-3">
          WEEK OF
        </div>
        <div className="col-md-12 d-flex justify-content-center">
          {mayBeLastWeek(endLastWeek)}
          {props.children}
          <div
            onClick={() => otherWeekData(nextWeek)}
            className="use-ajax next-week ajax-processed pl-4 pointer-link"
          >
            <img src={carouselRightArrow} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionWeekNavigation
