import React from "react"
import SessionScheduleDayTabContent from "./SessionScheduleDayTabContent"

const SessionWeekTabsContent = (props) => {
    var chamber = props.chamber
    const thedate = props.thedate
    const activedate = props.activedate
    const days = props.days
    const tabs_content = days.map((myday, idx) => {
        return (
            <SessionScheduleDayTabContent key={idx} chamber={chamber} thedate={myday} activedate={activedate} />
        )
    })

    return (
        <div className="tabContent ">
            {tabs_content}
        </div>
    )
}

export default SessionWeekTabsContent
