import React, { useState, useEffect } from "react"
import axios from "axios"
import moment from 'moment'
import MediaQuery from "react-responsive"
import ScheduleItem from "../ScheduleItem"

function maybeActive(active, mydate) {
    console.log(`${active} and ${mydate}`)
    if (active == mydate) {
        return "nav-link active"
    }
    else {
        return "nav-link"
    }
}

const ActivityUrl = (
  activity_link,
  schedule_item_title,
  chamber,
  committee_type
) => {
  if (activity_link == "") {
    return schedule_item_title
  } else {
    var chamber_title = IsCommitteeOfReference(committee_type) ? chamber : ""
    return (
      <a href={activity_link}>
        {chamber_title} {schedule_item_title}
      </a>
    )
  }
}

const IsCommitteeOfReference = (committee_type) => {
  return committee_type.includes("Reference")
}

function maybeGrid(grid) {
  console.log(`grid is ${grid}`)
  if (grid != "") {
    return grid
  } else {
    return (
      <div className="row p-0 d-flex justify-content-center">
        <div className="col-11 px-5 no-activities-listed py-3">
          No activities listed
        </div>
      </div>
    )
  }
}

const ScheduleDayTabHeader = (props) => {
    const [schedule_items, setScheduleItems] = useState([])
    var chamber = props.chamber
    const activedate = moment(props.activedate).format("YYYYMMDD")
    const mydate = moment(props.thedate).format("YYYYMMDD")
    const date_str = moment(props.thedate).format("MMM DD")
    const dayofweek = moment(props.thedate).format("ddd")
    const tab_str = `ugh${moment(props.thedate).format("YYYYMMDD")}`
    const tab_tab = `ugh${tab_str}-tab`
    const tab_link = `#${tab_str}`
    const tab_id = "ugh" + moment(props.thedate).format("YYYYMMDD")
    const { noScheduleDay } = props
    useEffect(() => {
      axios
        .get("/api/v1/schedule_items.json", {
          params: {
            date: props.thedate,
            chamber: chamber,
            distinct: true,
          },
        })
        .then((resp) => setScheduleItems(resp.data.data))
        .catch((data) => console.log("error", data))
    }, [schedule_items.length, chamber])

    const grid = schedule_items.map((si, index) => {
      return (
        <ScheduleItem
          key={index}
          dt={si.attributes.time_str}
          activity={ActivityUrl(
            si.attributes.activity_link,
            si.attributes.schedule_item_title,
            si.attributes.chamber,
            si.attributes.committee_type
          )}
          location={si.attributes.location}
          si_id={si.attributes.id}
          si_category={si.attributes.schedule_item_category}
          agenda_url={si.attributes.agenda_url}
          audio_id={si.attributes.audio_id}
          event_id={si.attributes.event_id}
        />
      )
    })



    return (
      <>
        <MediaQuery minWidth={768}>
          <>
            <li className="nav-item">
              <a
                className={maybeActive(activedate, mydate)}
                data-toggle={noScheduleDay ? "" : "tab"}
                href={tab_link}
                id={tab_tab}
                role="tab"
                aria-controls={tab_str}
                aria-selected="true"
              >
                <div>
                  <div className="row p-0 pb-2 d-flex justify-content-center session-schedule-day">
                    <span
                      className={
                        noScheduleDay
                          ? "session-schedule-week-day p-1"
                          : "background-white p-1"
                      }
                    >
                      {dayofweek.toUpperCase()}
                    </span>
                  </div>

                  <div
                    className={
                      noScheduleDay
                        ? "row p-0 pb-2 d-flex justify-content-center session-schedule-date disabled-session-schedule"
                        : "row p-0 pb-2 d-flex justify-content-center schedule-date session-schedule-date"
                    }
                  >
                    <span>{date_str.toUpperCase()}</span>
                  </div>
                </div>
              </a>
            </li>
          </>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className="row p-0 py-2">
            <div className="col-12">
              <a
                className={maybeActive(activedate, mydate)}
                data-toggle={noScheduleDay ? "" : "tab"}
                href={tab_link}
                id={tab_tab}
                role="tab"
                aria-controls={tab_str}
                aria-selected="true"
              >
                <div>
                  <div className="row p-0 pb-2 d-flex justify-content-center session-schedule-day">
                    <span
                      className={
                        noScheduleDay
                          ? "session-schedule-week-day p-1"
                          : "background-white p-1"
                      }
                    >
                      {dayofweek.toUpperCase()}
                    </span>
                  </div>

                  <div
                    className={
                      noScheduleDay
                        ? "row p-0 pb-2 d-flex justify-content-center session-schedule-date disabled-session-schedule"
                        : "row p-0 pb-2 d-flex justify-content-center schedule-date session-schedule-date"
                    }
                  >
                    <span>{date_str.toUpperCase()}</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-12 p-0 pb-md-5">
              {maybeGrid(grid)}
            </div>
          </div>
        </MediaQuery>
      </>
    )
}

export default ScheduleDayTabHeader
